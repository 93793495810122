<template>
  <div class="row-courses-details">
    <v-simple-table dense v-if="coursesData.length">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $tt.capitalize($t('labels.courses')) }}
            </th>
            <th class="text-left">
              {{ $tt.capitalize($t('reports.course.progress')) }}
            </th>
            <th class="text-left">
              {{ $tt.capitalize($t('reports.course.done')) }}
            </th>
            <th class="text-left">
              {{ $tt.capitalize($t('reports.course.doneDate')) }}
            </th>
            <th class="text-right">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in coursesData" :key="item._id">
            <td>{{ getCourseTitle(item.course) }}</td>
            <td class="row-progress">
              <div>
                <list-progress-bar
                  :value="item.progress.progress"
                  width="150"
                  linear />
              </div>
              <div>{{ item.progress.progress }}%</div>
            </td>
            <td>
              <v-icon v-if="item.progress.done">mdi-check-bold</v-icon>
              <span v-else>-</span>
            </td>
            <td>
              {{
                item.progress.done_date
                  ? getFormatedDate(item.progress.done_date)
                  : '-'
              }}
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="action-btns">
      <button @click="viewUser" class="lower-btn">Dados do usuário</button>
      <button v-if="loginAs" @click="loginAsFunc" class="lower-btn">
        Login as this student
      </button>
    </div>
  </div>
</template>

<script>
import * as courseService from '../../services/courses';
import moment from 'moment';
import ListProgressBar from '../shared/ListProgressBar.vue';

export default {
  props: {
    studentID: [Number, String],
    loginAs: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListProgressBar,
  },
  computed: {
    coursesData() {
      try {
        if (!this.$store.state.report.studentView.courses)
          throw new Error('nao exite ainda');
        return this.$store.state.report.studentView.courses;
      } catch (e) {
        return [];
      }
    },
  },
  methods: {
    getCourseTitle(course) {
      const courseLang = courseService.getCurrentLanguage(course);
      return course.title[courseLang];
    },
    getFormatedDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    viewUser() {
      this.$router.push({
        name: 'user-view-profile',
        params: {
          id: this.studentID,
        },
      });

      this.$gtm.trackEvent({
        category: 'users-admin-actions',
        event: 'click',
        action: 'users-click',
        label: 'users-view-profile',
        value: `view-${this.studentID}`,
      });
    },
    async loginAsFunc() {
      await this.$store.dispatch('auth/loginAs', this.studentID);
      this.$router.push({
        name: 'course-list',
      });

      this.$gtm.trackEvent({
        category: 'users-admin-actions',
        event: 'click',
        action: 'users-click',
        label: 'users-login-as',
        value: `login-as-${this.studentID}`,
      });
    },
  },
};
</script>

<style lang="scss">
.row-courses-details {
  padding-top: 10px;
  padding-bottom: 30px;

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 48px !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
      .v-data-table__empty-wrapper
    ) {
    background-color: #f5f6f6;
  }
  tr:nth-child(even) {
    background-color: #eeeeee;
  }
  .row-progress {
    display: flex;

    height: 100%;
    align-items: center;

    & > div:last-child {
      margin-left: 10px;
    }
  }
  .lower-btn {
    width: 49%;
    text-align: center;
    background-color: #fafbfb;
    border: 2px solid #bec4c9;
    border-radius: 8px;
    margin-top: 24px;
    line-height: 48px;
  }

  .action-btns {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
  }
}
</style>
