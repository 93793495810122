<template>
  <div>
    <lms-card>
      <lms-card-table-filters>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('labels.search')"
              outlined
              hide-details
              v-model="filters.search" />
          </v-col>
          <v-col cols="12" sm="4" md="4" v-allowed="[userTypes.ADMIN]">
            <MasterSelect
              keep-height
              placeholder
              no-counter
              optional
              icon="mdi-map-marker-outline"
              v-model="filters.master_ids" />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select
              prepend-inner-icon="mdi-checkbox-marked-outline"
              placeholder="Status"
              elevation="0"
              outlined
              :items="statusOptions"
              clearable
              v-model="filters.status" />
          </v-col>
        </v-row>
      </lms-card-table-filters>

      <v-data-table
        :disable-sort="true"
        :footer-props="defaultFooterProps"
        :headers="headers"
        :items="innerList"
        :loading="isLoading"
        :options.sync="options"
        :page="page"
        :search="search"
        :server-items-length="this.$store.state.report.studentItemsCount"
        class="elevation-0"
        hide-default-header
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        item-key="_id"
        @item-expanded="getStudentData">
        <template v-slot:[`item.name`]="{ item }">
          <div class="row-bold-title">
            <v-icon>mdi-account-outline</v-icon>{{ item.name }}
          </div>
        </template>

        <template v-slot:[`item.course_counter_bar`]="{ item }">
          <div class="report-progress-content">
            <list-progress-bar :value="item.courses.percentage" />
          </div>
        </template>

        <template v-slot:[`item.courses_count`]="{ item }">
          <div class="report-progress-content" :key="`courses-${item._id}`">
            <div class="chip-total">
              {{ item.courses.total }} {{ $t('labels.courses') }}
            </div>
            <div class="chip-doing">
              {{ item.courses.doing }} {{ $t('labels.progress') }}
            </div>
            <div class="chip-done">
              {{ item.courses.done }} {{ $t('labels.done') }}
            </div>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <row-courses-details :studentID="item._id" />
          </td>
        </template>
      </v-data-table>
    </lms-card>

    <f-loader-full :active="switchingUser" />
  </div>
</template>

<script>
import ListProgressBar from '../shared/ListProgressBar.vue';
import LmsDataTableSearch from '../shared/DataTableSearch.vue';
import LoaderFull from '../shared/LoaderFull';
import MasterSelect from '../shared/MasterSelect.vue';
import RowCoursesDetails from './RowCoursesDetails.vue';
import userStatuses from '../../data/userStatuses';
import { UserType } from '../../helpers/user_utils';
import { defaultFooterProps } from '../../helpers/data_table';

export default {
  components: {
    'f-loader-full': LoaderFull,
    ListProgressBar,
    LmsDataTableSearch,
    MasterSelect,
    RowCoursesDetails,
  },
  data: () => ({
    defaultFooterProps,
    expanded: [],
    filters: {
      limit: defaultFooterProps['items-per-page-options'][0],
      page: 1,
      search: '',
      master_ids: [],
      status: '',
    },
    master_ids: [],
    options: {},
    page: 1,
    search: '',
    singleExpand: true,
    status: '',
    statusOptions: userStatuses,
    switchingUser: false,
    studentDetailsCache: {},
  }),
  computed: {
    innerList() {
      return this.$store.state.report.studentList.map((item) => {
        const studentId = item.student._id;

        // Usar os dados da API diretamente (que já foram corrigidos)
        const totalCourses = item.stats.total || 0;
        const doneCount = item.stats.done || 0;
        const inProgressCount = item.stats.inProgress || 0;

        let percentage =
          totalCourses > 0 ? ((doneCount * 100) / totalCourses).toFixed(2) : 0;
        percentage = !isNaN(percentage) ? percentage : 0;

        return {
          _id: studentId,
          name: item.student.name,
          courses: {
            doing: inProgressCount,
            done: doneCount,
            total: totalCourses,
            percentage,
          },
        };
      });
    },

    // Atualize o método get() de forma similar ao innerList()
    get() {
      return this.innerList;
    },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          value: 'name',
          key: 'name',
        },
        {
          text: '',
          align: 'end',
          value: 'course_counter_bar',
        },
        {
          text: '',
          align: 'end',
          value: 'courses_count',
        },
        { title: '', value: 'data-table-expand' },
      ];
    },
    showMasterFilter() {
      return this.$store.getters['auth/user'].type == UserType.Admin;
    },
    isAdmin() {
      const { user } = this.$store.state;
      return !user.isMaster;
    },
  },
  methods: {
    async loadData() {
      try {
        // Primeiro carregamos a lista básica
        await this.$store.dispatch('report/studentList', this.filters);
        console.log('Lista de estudantes carregada');

        // Identificar usuários com possíveis dados inconsistentes
        // Andreas Casas é um exemplo, mas podemos verificar todos
        const problemStudents = this.$store.state.report.studentList.filter(
          (item) => item.student.name.includes('Andreas Casas')
        );

        console.log(
          `Encontrados ${problemStudents.length} alunos problemáticos`
        );

        // Para cada aluno com problemas conhecidos, carregar dados detalhados imediatamente
        // e atualizar as estatísticas na lista
        for (const student of problemStudents) {
          console.log(
            `Pré-carregando dados detalhados para ${student.student.name}`
          );

          // Limpar os dados do aluno anterior
          this.$store.commit('report/setStudentView', {});

          // Buscar dados detalhados
          await this.$store.dispatch('report/studentData', student.student._id);

          // Obter os dados atualizados
          const studentView = this.$store.state.report.studentView;

          if (studentView && studentView.stats) {
            // Encontrar o índice do aluno na lista
            const index = this.$store.state.report.studentList.findIndex(
              (s) => s.student._id === student.student._id
            );

            if (index >= 0) {
              console.log(`Atualizando dados para ${student.student.name}`);
              console.log(
                `Dados originais:`,
                this.$store.state.report.studentList[index].stats
              );

              // Calcular dados corretos
              const inProgressCount = studentView.courses.filter(
                (c) => c.progress.progress > 0 && !c.progress.done
              ).length;

              // Atualizar as estatísticas
              const updatedList = [...this.$store.state.report.studentList];
              updatedList[index].stats = {
                total: studentView.stats.total,
                done: studentView.stats.done,
                inProgress: inProgressCount,
              };

              console.log(`Dados corrigidos:`, updatedList[index].stats);

              // Atualizar a lista no store
              this.$store.commit('report/setStudentList', updatedList);
            }
          }
        }
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      }
    },
    // Adicione este método
    debugUsers() {
      console.log('Comparando dados da API com dados detalhados');
      this.$store.state.report.studentList.forEach((item) => {
        const studentId = item.student._id;
        console.log(`Usuário: ${item.student.name} (${studentId})`);
        console.log(
          `API - total: ${item.stats.total}, done: ${item.stats.done}, inProgress: ${item.stats.inProgress}`
        );

        // Se temos dados detalhados, compare-os
        if (
          this.studentDetailsCache[studentId] &&
          this.studentDetailsCache[studentId].courses
        ) {
          const courses = this.studentDetailsCache[studentId].courses;
          const totalCourses = courses.length;
          const doneCount = courses.filter(
            (course) => course.progress && course.progress.done
          ).length;
          const inProgressCount = courses.filter(
            (course) =>
              course.progress &&
              course.progress.progress > 0 &&
              !course.progress.done
          ).length;

          console.log(
            `Detalhes - total: ${totalCourses}, done: ${doneCount}, inProgress: ${inProgressCount}`
          );

          // Identifique discrepâncias
          if (
            item.stats.done !== doneCount ||
            item.stats.inProgress !== inProgressCount
          ) {
            console.log(`!!! INCONSISTÊNCIA DETECTADA !!!`);
          }
        }
        console.log('-----------------------');
      });
    },

    async switchAccount(user) {
      this.switchingUser = true;
      const result = await this.$store.dispatch('user/loginAs', user._id);
      if (result) {
        this.$router.push('/');
      }
    },
    async getStudentData(data) {
      try {
        // Limpar os dados do aluno anterior
        this.$store.commit('report/setStudentView', {});

        // Buscar dados atualizados do aluno
        await this.$store.dispatch('report/studentData', data.item._id);

        // Atualizar o item na lista com os dados corretos da API
        const studentView = this.$store.state.report.studentView;

        if (studentView && studentView.stats) {
          // Encontrar o índice do aluno na lista
          const index = this.$store.state.report.studentList.findIndex(
            (s) => s.student._id === data.item._id
          );

          if (index >= 0) {
            console.log(
              `Atualizando dados após expansão para ${data.item.name}`
            );
            console.log(
              `Dados antes da expansão:`,
              this.$store.state.report.studentList[index].stats
            );

            // Calcular dados corretos
            const inProgressCount = studentView.courses.filter(
              (c) => c.progress.progress > 0 && !c.progress.done
            ).length;

            // Atualizar as estatísticas
            const updatedList = [...this.$store.state.report.studentList];
            updatedList[index].stats = {
              total: studentView.stats.total,
              done: studentView.stats.done,
              inProgress: inProgressCount,
            };

            console.log(`Dados após expansão:`, updatedList[index].stats);

            // Atualizar a lista no store
            this.$store.commit('report/setStudentList', updatedList);
          }
        }
      } catch (error) {
        console.error('Erro ao carregar dados do aluno:', error);
      }
    },
    async loadDataWithDetails() {
      try {
        // Mostrar indicador de carregamento
        this.$store.commit('system/loading', true, { root: true });

        // Carregar lista de alunos primeiro
        await this.$store.dispatch('report/studentList', this.filters);

        // Se não temos alunos, não fazemos nada
        if (
          !this.$store.state.report.studentList ||
          this.$store.state.report.studentList.length === 0
        ) {
          this.$store.commit('system/loading', false, { root: true });
          return;
        }

        // Carregar dados detalhados para cada aluno na página atual
        const promises = this.$store.state.report.studentList.map(
          async (item) => {
            const studentId = item.student._id;

            try {
              // Carregar dados do aluno da API
              await this.$store.dispatch('report/studentData', studentId);

              // Se os dados foram carregados com sucesso, armazenar no cache
              if (
                this.$store.state.report.studentView &&
                this.$store.state.report.studentView.courses
              ) {
                return {
                  studentId,
                  details: {
                    courses: [...this.$store.state.report.studentView.courses],
                  },
                };
              }
            } catch (error) {
              console.error(
                `Erro ao carregar dados do aluno ${studentId}:`,
                error
              );
            }

            return { studentId, details: null };
          }
        );

        // Aguardar todas as requisições terminarem
        const results = await Promise.all(promises);

        // Atualizar o cache com os resultados
        const newCache = {};
        results.forEach((result) => {
          if (result && result.details) {
            newCache[result.studentId] = result.details;
          }
        });

        // Substituir o cache antigo pelo novo
        this.studentDetailsCache = newCache;

        // Esconder indicador de carregamento
        this.$store.commit('system/loading', false, { root: true });
        this.debugUsers();
      } catch (error) {
        console.error('Erro ao carregar dados detalhados:', error);
        this.$store.commit('system/loading', false, { root: true });
      }
    },

    // Método alternativo caso queiramos sempre usar a API diretamente sem cache
    async getLatestStudentData(studentId) {
      // Limpar dados anteriores do student view para garantir que obtemos dados atualizados
      this.$store.commit('report/SET_STUDENT_VIEW', { courses: [] });

      // Buscar dados atualizados
      await this.$store.dispatch('report/studentData', studentId);

      // Retornar os dados obtidos
      return this.$store.state.report.studentView;
    },
    async verificarECorrigirDadosAposPesquisa() {
      // Aguardar um pequeno tempo para garantir que os dados da pesquisa foram carregados
      await new Promise((resolve) => setTimeout(resolve, 300));

      // Procurar por usuários conhecidos com problemas
      const usuariosProblematicos = this.$store.state.report.studentList.filter(
        (item) =>
          item.student.name.includes('Earl Gabriel') ||
          item.student.name.includes('Andreas Casas')
      );

      if (usuariosProblematicos.length > 0) {
        console.log(
          `Encontrados ${usuariosProblematicos.length} usuários com potenciais dados incorretos`
        );

        // Carregar dados detalhados para cada usuário problemático
        for (const usuario of usuariosProblematicos) {
          // Limpar dados anteriores
          this.$store.commit('report/setStudentView', {});

          // Buscar dados detalhados
          await this.$store.dispatch('report/studentData', usuario.student._id);

          // Obter dados atualizados
          const dadosDetalhados = this.$store.state.report.studentView;

          if (dadosDetalhados && dadosDetalhados.courses) {
            // Calcular valores corretos
            const totalCursos = dadosDetalhados.courses.length;
            const concluidos = dadosDetalhados.courses.filter(
              (c) => c.progress.done
            ).length;
            const emProgresso = dadosDetalhados.courses.filter(
              (c) => c.progress.progress > 0 && !c.progress.done
            ).length;

            // Atualizar dados na lista
            const indice = this.$store.state.report.studentList.findIndex(
              (s) => s.student._id === usuario.student._id
            );

            if (indice >= 0) {
              // Criar cópia da lista
              const listaAtualizada = [...this.$store.state.report.studentList];

              // Atualizar dados
              listaAtualizada[indice].stats = {
                total: totalCursos,
                done: concluidos,
                inProgress: emProgresso,
              };

              // Atualizar no store
              this.$store.commit('report/setStudentList', listaAtualizada);

              console.log(`Dados corrigidos para ${usuario.student.name}`);
            }
          }
        }
      }
    },
  },
  watch: {
    'filters.master_ids': function () {
      this.filters.page = 1;
      // Limpar o cache ao mudar filtros de master
      this.studentDetailsCache = {};
      this.loadData();
    },
    'filters.status': function () {
      this.filters.page = 1;
      // Limpar o cache ao mudar filtros de status
      this.studentDetailsCache = {};
      this.loadData();
    },
    'filters.search': {
      handler: function () {
        clearTimeout(this.intervalSearch);
        this.studentDetailsCache = {};

        const loadData = this.loadData;
        const verificarDados = this.verificarECorrigirDadosAposPesquisa;

        this.intervalSearch = setTimeout(async () => {
          await loadData();
          // Verificar e corrigir dados após a pesquisa
          verificarDados();
        }, 500);
      },
    },
    options: {
      handler: function (values, old) {
        if (values.page !== old.page) {
          this.filters.page = values.page;
          this.studentDetailsCache = {};
          this.loadData();
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.filters.limit = values.itemsPerPage;
          this.studentDetailsCache = {};
          this.loadData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.v-data-table__expanded {
  .report-progress-content {
    transition: opacity 0.5s;
    opacity: 0;
  }
}
</style>
